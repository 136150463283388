import * as React from "react"
import { Link } from 'gatsby'
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <main>
    <div className="flex min-h-full flex-col bg-gradient-to-t from-gray-50 to-primary-100 lg:relative">
      <div className="flex flex-grow flex-col">
        <div className="flex flex-grow flex-col bg-transparent">
          <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 pl-4 pt-4 sm:pl-2 md:pl-0">
              <Link to="/" className="inline-flex">
                <span className="sr-only">Dimension Lab</span>
                <StaticImage
                  src="../images/lightLogo.svg"
                  alt="Workflow"
                  placeholder="none"
                  loading="eager"
                  height={50}
                />
              </Link>
            </div>
            <div className="my-auto flex-shrink-0 py-16 sm:py-32">
              <p className="text-base font-semibold text-primary-600">404</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-primary-800 sm:text-5xl">Page Not Found</h1>
              <p className="mt-2 text-lg font-base text-gray-500">We can't find the page you’re looking for.</p>
              <div className="mt-6">
                <Link to="/" className="no-underline text-base font-semibold text-primary-600 hover:text-primary-400">
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </main>
)

export default NotFoundPage
